<template>
  <div>
    <custom-toolbar
      :title="'Détails du code de réduction'"
      @refresh="refreshObjet"
    />
    <v-row class="mt-1">
      <v-col
        cols="12"
        lg="4"
        md="5"
      >
        <v-skeleton-loader
          v-show="isDialogVisible"
          boilerplate
          class="mx-auto"
          elevation="2"
          max-width="360"
          type="card-avatar, article, article, actions"
        ></v-skeleton-loader>
        <reduct-code-panel v-show="!isDialogVisible" :object="$store.state.reductCode.reductCode" />
      </v-col>
      <v-col
        cols="12"
        lg="8"
        md="8"
      >
        <v-skeleton-loader
          v-show="isDialogVisible"
          boilerplate
          class="mx-auto"
          elevation="2"
          max-width="840"
          type="table-heading,divider, image, article, article, actions"
        ></v-skeleton-loader>
        <v-tabs v-show="!isDialogVisible"
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-show="!isDialogVisible"
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <reduct-code-users-list :rec_code_id="$store.state.reductCode.reductCode.id"/>
          </v-tab-item>
          <v-tab-item>
            <!--            <road-payment :road="road" />-->
          </v-tab-item>
          <v-tab-item>
          </v-tab-item>
        </v-tabs-items>
        <v-row>
          <v-col
            cols="12"
            lg="12"
            md="12"
          >
            <!--            <g-map-test/>-->
            <!--            <road-map />-->
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, ref } from '@vue/composition-api'
import {
  mdiArrowLeftBoldCircleOutline,
  mdiCashMultiple,
  mdiMapMarkerDistance,
  mdiRefresh,
} from '@mdi/js'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import ReductCodePanel from '@core/components/reductCode/ReductCodePanel'
import ReductCodeUsersList from '@core/components/reductCode/ReductCodeUsersList'
import router from '@/router'
import store from '@/store'
import CustomToolbar from "@core/components/toolbar/CustomToolbar";
import {watch} from "@vue/composition-api/dist/vue-composition-api";

export default {
  name: 'ReductCodeDetails',
  components: {CustomToolbar, ReductCodeUsersList, ReductCodePanel, LoaderDialog },
  setup() {
    const isDialogVisible = ref(false)
    const userTab = ref(null)
    // eslint-disable-next-line no-shadow,no-use-before-define
    const route = getCurrentInstance().proxy.$route

    const getElt = () => {
      const id = route.params.id !== undefined ? route.params.id : null
      if (id !== null) {
        store.state.destination.field.road_id = id
        if (store.state.road.road === null || store.state.road.road.id !== id) {
          isDialogVisible.value = true
          store.dispatch('reductCode/getReductCodeById', id).then(() => {
            isDialogVisible.value = false
          }).catch(error => {
            console.log(error)
          })
        }
      }
    }

    const refreshObjet = () => {
      const id = route.params.id !== undefined ? route.params.id : null
      if (id !== null) {
        isDialogVisible.value = true
        store.dispatch('reductCode/getReductCodeById', id).then(() => {
          isDialogVisible.value = false
        }).catch(error => {
          console.log(error)
        })
      }
    }

    onMounted(getElt)

    const prev = () => {
      router.back()
    }

    watch(() => store.state.user.lang, lang => {
      refreshObjet()
    })

    const tabs = [
      { icon: mdiMapMarkerDistance, title: 'Consommateurs' },
    ]

    return {
      isDialogVisible,
      getElt,
      prev,
      refreshObjet,
      tabs,
      userTab,
      icons: {
        mdiRefresh,
        mdiArrowLeftBoldCircleOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
