<template>
  <div>
    <v-card>
      <div class="d-flex align-center justify-space-between">
        <div>
          <v-card-title class="text-no-wrap">
            Code de réduction
          </v-card-title>
          <v-card-subtitle>Détails sur le code de réduction</v-card-subtitle>
        </div>

        <v-avatar
          color="primary"
          size="48"
          class="v-avatar-light-bg primary--text me-5"
        >
          <v-icon
            size="30"
            color="primary"
          >
            {{ icons.mdiCodeBraces }}
          </v-icon>
        </v-avatar>
      </div>

      <v-card-text>
        <v-list>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiImageText }}
              </v-icon>
              Libellé:
            </span>
            <span class="text--secondary font-weight-bold">{{ $store.state.reductCode.reductCode.label }}</span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiCodeBraces }}
              </v-icon>
              Code :
            </span>
            <span class="text--secondary font-weight-bold">
              <v-chip
                color="primary"
                small
              >
                {{ $store.state.reductCode.reductCode.code }}
              </v-chip>
            </span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiInformation }}
              </v-icon>
              Valeur:
            </span>
            <span class="text--secondary font-weight-bold">
              <v-chip
                color="secondary"
                small
              >
                {{ $store.state.reductCode.reductCode.is_percent === true ? `${$store.state.reductCode.reductCode.value} %` : formatAmount($store.state.reductCode.reductCode.value) }}
              </v-chip>
            </span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiInformationVariant }}
              </v-icon>
              Quantité initiale:
            </span>
            <span class="text--secondary font-weight-bold">{{ $store.state.reductCode.reductCode.init_quantity }}</span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiInformationVariant }}
              </v-icon>
              Quantité par utilisateur:
            </span>
            <span class="text--secondary font-weight-bold">{{ $store.state.reductCode.reductCode.quantity_per_user }}</span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiInformationVariant }}
              </v-icon>
              Quantité restante:
            </span>
            <span class="text--secondary font-weight-bold">{{ $store.state.reductCode.reductCode.quantity_left }}</span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiClockStart }}
              </v-icon>
              Date de fin:
            </span>
            <span class="text--secondary font-weight-bold">{{ $store.state.reductCode.reductCode.end_date ? formattingStartDate($store.state.reductCode.reductCode.end_date) : null }}</span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiCalendar }}
              </v-icon>
              Date de création:
            </span>
            <span class="text--secondary font-weight-bold">{{ $store.state.reductCode.reductCode.created_at ? formattingDate($store.state.reductCode.reductCode.created_at) : null }}</span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiCalendarEdit }}
              </v-icon>
              Date de mise à jour:
            </span>
            <span class="text--secondary font-weight-bold">{{ $store.state.reductCode.reductCode.updated_at ? formattingDate($store.state.reductCode.reductCode.updated_at) : null }}</span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiCalendarMinus }}
              </v-icon>
              Date de suppression:
            </span>
            <span class="text--secondary font-weight-bold">{{ $store.state.reductCode.reductCode.deleted_at ? formattingDate($store.state.reductCode.reductCode.deleted_at) : null }}</span>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-tooltip
          bottom
          color="primary"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              class="primary mb-4 me-3 mt-4"
              x-large
              v-bind="attrs"
              icon
              @click="openForm(reductCode)"
              v-on="on"
              elevation="6"
            >
              <v-icon
                dark
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>Modifier</span>
        </v-tooltip>

        <v-tooltip
          bottom
          :color="$store.state.reductCode.reductCode.deleted_at == null ? 'error' : 'primary'"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              x-large
              icon
              class="mb-4 me-3 mt-4"
              :class="$store.state.reductCode.reductCode.deleted_at == null ? 'error' : 'primary'"
              v-bind="attrs"
              elevation="6"
              @click="openItem($store.state.reductCode.reductCode)"
              v-on="on"
            >
              <v-icon
                dark
              >
                {{ $store.state.reductCode.reductCode.deleted_at == null ? icons.mdiDeleteOutline : icons.mdiDeleteRestore }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $store.state.reductCode.reductCode.deleted_at == null ? 'Supprimer' : 'Restaurer' }}</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
    <reduct-code-form
      :dialog="dialog"
      :object="item"
      @clickout="closeDialog"
    />
    <confirm-dialog
      :message="messageDialog"
      :dialog-delete="dialogDelete"
      @clickout="dialogDelete = false"
      @submitted="deleteItem"
    />
  </div>
</template>

<script>
import {
  mdiRoad, mdiImageText, mdiCash, mdiClockStart, mdiBusArticulatedFront, mdiBusArticulatedEnd, mdiBadgeAccountAlert,
  mdiSeatPassenger, mdiCashMarker, mdiCalendar, mdiCalendarMinus, mdiCalendarEdit, mdiMapMarkerUp, mdiMapMarkerDown,
  mdiCodeBraces, mdiInformation, mdiInformationVariant, mdiPercentOutline, mdiPencilOutline, mdiDeleteOutline,
  mdiDeleteRestore,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { formatDate, formatStartDate } from '@core/date/dateFunctions'
import ReductCodeForm from '@core/components/reductCode/ReductCodeForm'
import ConfirmDialog from '@core/components/dialogs/ConfirmDialog'
import { intToCurrency, notify } from '@core/dynamicComponents/dynamicComponents'
import store from '@/store'

export default {
  name: 'ReductCodeCard',
  components: { ConfirmDialog, ReductCodeForm },
  props: {
    object: {
      type: Object,
      default: null,
    },
  },

  setup(props, { emit }) {
    const isDialogVisible = ref(false)
    const error = ref(null)
    const message = ref(null)
    const isAlertVisible = ref(false)
    const dialogDelete = ref(false)
    const dialog = ref(false)
    const messageDialog = ref('')
    const item = ref({})
    const reductCode = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    const formattingDate = date => formatDate(date)

    const formattingStartDate = date => formatStartDate(date)

    const openForm = val => {
      if (val) {
        val.end_date = new Date(val.end_date).toISOString().substr(0, 10)
        item.value = val
      } else {
        item.value = {
          id: null,
          label: null,
          code: null,
          value: null,
          init_quantity: null,
          quantity_per_user: null,
          is_percent: false,
          end_date: null,
        }
      }
      dialog.value = true
    }

    const closeDialog = () => {
      dialog.value = false
    }

    const formatAmount = amount => intToCurrency(store.state.user.lang, {
      style: 'currency',
      currency: 'XAF',
      minimumFractionDigits: 0,
    }, amount)

    const deleteItem = () => {
      isDialogVisible.value = true
      if (item.value.deleted_at === null) {
        store.dispatch('reductCode/deleteReductCode', item.value).then(response => {
          isDialogVisible.value = false
          isAlertVisible.value = false
          dialogDelete.value = false
          notify(true, true, response.message)
        }).catch(err => {
          message.value = err.response.data.message
          error.value = err.response.data.message
          isAlertVisible.value = true
          dialogDelete.value = false
          notify(false, true, err.response.data.message)
        })
      } else {
        store.dispatch('reductCode/restoreReductCode', item.value).then(response => {
          isDialogVisible.value = false
          isAlertVisible.value = false
          dialogDelete.value = false
          notify(true, true, response.message)
        }).catch(err => {
          message.value = err.response.data.message
          error.value = err.response.data.message
          isAlertVisible.value = true
          dialogDelete.value = false
          notify(false, true, err.response.data.message)
        })
      }
    }

    const openItem = object => {
      messageDialog.value = object.deleted_at === null ? `Voulez-vous supprimer le code ${object.label}?` : `Voulez-vous restaurer le code ${object.label}?`
      item.value = object
      console.log(item.value)
      dialogDelete.value = true
    }

    return {
      formatAmount,
      isDialogVisible,
      openItem,
      message,
      isAlertVisible,
      dialogDelete,
      deleteItem,
      closeDialog,
      messageDialog,
      item,
      dialog,
      openForm,
      formattingDate,
      formattingStartDate,
      error,
      reductCode,
      icons: {
        mdiRoad,
        mdiDeleteRestore,
        mdiDeleteOutline,
        mdiImageText,
        mdiCash,
        mdiClockStart,
        mdiBusArticulatedFront,
        mdiBusArticulatedEnd,
        mdiSeatPassenger,
        mdiCashMarker,
        mdiCalendar,
        mdiCalendarMinus,
        mdiPencilOutline,
        mdiCalendarEdit,
        mdiMapMarkerDown,
        mdiMapMarkerUp,
        mdiBadgeAccountAlert,
        mdiCodeBraces,
        mdiInformation,
        mdiInformationVariant,
        mdiPercentOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
