<template>
  <div>
    <reduct-code-card
      :object="$store.state.reductCode.reductCode"
      class="mb-4"
    />
    <reduct-code-owner
      :user="$store.state.reductCode.reductCode.user"
      title="Propriétaire"
      subtitle="Propriétaire du code"
    />
    <reduct-code-owner
      :user="$store.state.reductCode.reductCode.admin"
      title="Publié par"
      subtitle="Code réduction enregistré par"
    />
  </div>
</template>

<script>
import ReductCodeCard from '@core/components/reductCode/ReductCodeCard'
import ReductCodeOwner from '@core/components/reductCode/ReductCodeOwner'

export default {
  name: 'ReductCodePanel',
  components: { ReductCodeOwner, ReductCodeCard },
  setup() {

  },
}
</script>

<style scoped>

</style>
